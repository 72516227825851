<template>
  <VApp id="tt-app">
    <VMain>
      <RouterView />
    </VMain>

    <AppInfo />

    <VOverlay
      :value="loading"
      color="rgba(255, 255, 255, 1)"
      opacity="1"
    >
      <VProgressCircular
        indeterminate
        color="accent"
      />
    </VOverlay>

    <VSnackbar
      :value="snackbar.value"
      :timeout="snackbar.timeout"
      top
      :color="snackbar.color"
      @input="toggleSnack"
    >
      {{ snackbar.message }}
      <VBtn
        color="white"
        text
        @click="toggleSnack"
      >
        {{ $t('close') }}
      </VBtn>
    </VSnackbar>
  </VApp>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { toggleSnack } from '@/plugins/vuex/mutationTypes';
import AppInfo from '@/components/app/AppInfo.vue';

export default {
  name: 'AppLayout',
  components: {
    AppInfo,
  },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    ...mapState(['snackbar', 'loading']),
  },
  methods: {
    ...mapMutations([toggleSnack]),
  },
};
</script>
